@import "src/app_variables";

.RadioBox {
  border-radius: 0.5 * $spacer;
  cursor: pointer;
  padding-top: 0.25 * $spacer;
  padding-bottom: 0.25 * $spacer;
  margin-left: -$spacer; // for hover effect
  padding-left: $spacer; // for hover effect
  &:hover {
    background-color: #8884;
  }

  input,
  label {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;

    input,
    label {
      cursor: not-allowed;
    }
  }
}
