@import "src/app_variables";

$main-ctrl-height: 40px;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.TopNavigation {
  display: flex;
  align-items: start;
  padding: $spacer;
  border-bottom: $border;
  width: 100%;
  flex-direction: column;
  gap: calc($spacer * 0.5);

  @media (min-width: 576px) {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }

  .SelectOrgUnit {
    height: $main-ctrl-height;
    margin-left: 0;
    width: 300px;

    .SelectOrgUnit__control {
      height: $main-ctrl-height;
    }
  }

  .AdvancedSearch {
    width: 100%;
    margin-left: 0;

    @media (min-width: 576px) {
      margin-left: 10px;
      width: 300px;
    }

    .SearchInput {
      width: 100%;
      height: $main-ctrl-height;

      .form-control {
        height: 100%;
      }

      @media (min-width: 576px) {
        width: 300px;
      }
    }
  }

  .header {
    display: flex;
    flex-direction: column;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
