.SearchInput {
  position: relative;

  .search-input-additional {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;

    i {
      font-size: 12px;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
