.CustomTable {
  &.table-fixed-header {
    position: relative;
    thead {
      th {
        background: #fff;
        position: sticky;
        z-index: 100;
        top: 0;
        border-top: 0;
        height: 36px;
        vertical-align: middle;
        &:after {
          content: "";
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          border-bottom: 3px solid #dee2e6;
        }
      }
    }
    tfoot {
      th {
        border-top: 3px solid #dee2e6;
      }
    }
  }

  tbody {
    td {
      height: 44px;
      vertical-align: middle;

      &.height-unset {
        height: unset !important;
      }
    }
  }

  &.table-sm {
    tbody {
      td {
        height: 36px;
      }
    }
  }
}
