@import "src/app_variables";

.Home {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  width: 100%;

  @media (min-width: 576px) {
    flex-direction: row;
  }

  .OpenDetailsButton {
    display: flex;
    justify-content: end;

    @media (min-width: 576px) {
      display: none;
    }
  }

  .ZsbSorterTable {
    flex: 2;
    overflow: auto;

    .checkbox-cell {
      padding-left: $spacer;
      cursor: pointer;

      input {
        cursor: pointer;
      }

      &:hover {
        background-color: #8884 !important;
      }
    }

    .table > :not(:first-child) {
      border-top: unset;
    }
  }
}
