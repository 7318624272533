@import "src/app_variables";

.ZsbSorterConfigDetails {
  position: absolute;
  flex: unset;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: white;
  overflow: auto;

  @media (min-width: 576px) {
    position: relative;
    flex: 1;
    border-left: 1px solid #dee2e6;
  }

  &.no-config {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    display: flex;
    height: 37px;
    background-color: $hermes-lightblue;
    justify-content: space-between;
    padding-left: $details-spacer;
    padding-right: $spacer;
    overflow: hidden;
    align-items: center;
    .details-title {
      max-width: 80%;
    }

    .closeButton {
      display: flex;

      @media (min-width: 576px) {
        display: none;
      }
    }
  }
  .details-body {
    padding: $spacer $details-spacer;
  }
}

.RadioGroupSetting {
  margin-bottom: $details-spacer;
}
