@import "src/app_variables";

.ZsbSorterConfigRow {
  cursor: pointer;

  &.isSelected {
    background-color: $hermes-lightblue;
  }
}

.highlighted {
  background-color: adjust-color($hermes-blue, $alpha: -0.7);
}
