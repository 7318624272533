$hermes-blue: #0091cd;
$form-feedback-valid-color: #ced4da;
$primary: #0091cd;
$link-color: $hermes-blue;
$gray: #eceff1;
$muted: #333334;

:root {
  --primary: #0091cd;
  --accent: #edbc7c;
  --accent-light: #f4d0a1;
  --sidebar-prod: $hermes-blue;
  --sidebar-staging: #fc9701;
  --sidebar-local: #6c55ff;
}

@import "/node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "/node_modules/bootstrap/scss/bootstrap";
@import "/node_modules/@hermesgermany/last-mile-components/dist/style.css";

.btn-primary,
.btn-primary:hover {
  color: #ffffff;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 14px;
}

.App {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100vh;

  @media screen and (max-width: 767px) {
    input,
    select,
    textarea {
      font-size: 16px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.font-weight-bold {
  font-weight: 600;
}

.flex-1 {
  flex: 1;
}

.nowrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  font-weight: 600 !important;
}
